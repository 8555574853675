import { FC } from 'react';
import { City } from '@api/types/cities';
import { useMediaQuery } from '@chakra-ui/react';
import Button from '@components/common/Button';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { tw } from '@utils/tailwind-utils';
import { addDays, format } from 'date-fns';
import { useTranslation } from 'next-i18next';

import { SearchParamsType } from '../../api/types/searchParamsSchema';

interface SearchButtonProps {
  cities: City[];
  className?: string;
  currentCity?: string;
  searchValues?: SearchParamsType;
  onOpenSearchBarModal: () => void;
  noDates: boolean;
}

const formatDate = (date?: string, add?: number) => {
  return format(!date && add ? addDays(new Date(), add) : date ? new Date(date) : new Date(), 'dd/MM/yyyy');
};

const SearchButton: FC<SearchButtonProps> = ({ searchValues, onOpenSearchBarModal, className, noDates }) => {
  const [isDropdown] = useMediaQuery(['(max-width: 680px)']);
  const { t } = useTranslation(['countries', 'search']);
  return (
    <div
      className={tw('flex min-h-[54px] w-full gap-x-1', className)}
      onClick={() => isDropdown && onOpenSearchBarModal()}
    >
      <div className="my-0 flex w-full flex-col bg-rentennials-inputBg px-2 py-4 min-[680px]:my-2  min-[680px]:rounded-l-2xl min-[680px]:px-5 min-[680px]:py-2">
        <div
          className="flex h-full flex-col items-start justify-start gap-x-3 gap-y-0.5 pl-1 font-bree-serif text-[14px]
        font-normal text-rentennials-input min-[521px]:flex-row min-[521px]:items-center min-[521px]:justify-between min-[550px]:items-center "
        >
          <div className="gap-x-w flex items-center">
            <MapPinIcon className="h-8 w-7 text-rentennials-primary" />
            <div className="flex flex-col">
              {!searchValues?.city && !searchValues?.country && (
                <span className="pl-1 font-bree-serif text-[16px] leading-4 text-rentennials-primary">
                  {t('search:destination')}
                </span>
              )}
              {searchValues?.city && (
                <span className="pl-1 font-bree-serif text-[16px] leading-4 text-rentennials-primary">
                  {searchValues.city}
                </span>
              )}
              {searchValues?.country && (
                <span
                  className={tw('pl-1 font-bree-serif text-sm leading-4 text-rentennials-primary', {
                    'text-base': !searchValues?.city
                  })}
                >
                  {t(searchValues.country)}
                </span>
              )}
            </div>
          </div>
          {!noDates && (
            <div className="flex flex-col gap-x-1 min-[320px]:flex-row">
              <div>
                <span>
                  {formatDate(searchValues?.fromDate)}
                  <span className=""> | </span>
                  <span className="text-rentennials-primary">{searchValues?.fromDateTime || '08:00'}</span>
                </span>
              </div>
              -
              <div>
                <span>
                  {formatDate(searchValues?.toDate, 2)}
                  <span className=""> | </span>
                  <span className="text-rentennials-primary ">{searchValues?.toDateTime || '08:00'}</span>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="my-0 flex items-center  justify-center bg-rentennials-inputBg px-4 py-2 min-[680px]:my-2 min-[680px]:rounded-r-2xl">
        <Button onClick={onOpenSearchBarModal} className="bg-rentennials-inputBg hover:bg-rentennials-inputBg">
          <ChevronDownIcon className="h-6 w-6  stroke-2 text-rentennials-input" />
        </Button>
      </div>
    </div>
  );
};

export default SearchButton;
