import { z } from 'zod';

const RentennialsCitySchema = z.object({
  _id: z.string(),
  name: z.string(),
  country: z.string(),
  cod: z.string()
});

export const RentennialsCitiesSchema = z.array(RentennialsCitySchema);

export type RentennialsCity = z.infer<typeof RentennialsCitySchema>;
export type RentennialsCities = z.infer<typeof RentennialsCitiesSchema>;
