import { deepKeysToCamel } from '@utils/convertCase';

import { City } from '../types/cities';
import { RentennialsCities } from '../types/citiesSchema';

export const transformCities = (cities: RentennialsCities) => {
  return deepKeysToCamel<RentennialsCities, City[]>(
    cities.sort(({ name: nameA }, { name: nameB }) => {
      if (nameA > nameB) {
        return 1;
      }
      if (nameA === nameB) {
        return 0;
      }
      return -1;
    })
  );
};
