import { FC } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import SearchBar, { SearchBarProps } from './SearchBar';

interface SearchModalProps extends SearchBarProps {
  onCancel: () => void;
}

const SearchModal: FC<SearchModalProps> = ({ onCancel, currentCity, ...searchBarProps }) => {
  return (
    <Modal isOpen onClose={onCancel} isCentered size={['sm', 'xl']} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent
        sx={{
          borderRadius: '20px',
          mx: 2
        }}
      >
        <ModalCloseButton>
          <XMarkIcon className="inline h-6 w-6 stroke-2  text-[#B7B7B7]" />
        </ModalCloseButton>
        <ModalBody sx={{ px: 10 }}>
          <SearchBar
            currentCity={currentCity}
            isModal
            {...searchBarProps}
            className={searchBarProps.className ? searchBarProps.className + ' px-2' : 'px-2'}
            size="md"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
