import { CalendarConfigs } from 'aeht-chakra-dayzed-datepicker/dist/utils/commonTypes';

export const defaultCalendarConfig: CalendarConfigs = {
  dateFormat: 'dd/MM/yy',
  dayNames: [
    'search:range-pick.dayOfWeek.sunday',
    'search:range-pick.dayOfWeek.monday',
    'search:range-pick.dayOfWeek.tuesday',
    'search:range-pick.dayOfWeek.wednesday',
    'search:range-pick.dayOfWeek.thursday',
    'search:range-pick.dayOfWeek.friday',
    'search:range-pick.dayOfWeek.saturday'
  ],
  monthNames: [
    'search:range-pick.months.january',
    'search:range-pick.months.february',
    'search:range-pick.months.march',
    'search:range-pick.months.april',
    'search:range-pick.months.may',
    'search:range-pick.months.june',
    'search:range-pick.months.july',
    'search:range-pick.months.august',
    'search:range-pick.months.september',
    'search:range-pick.months.october',
    'search:range-pick.months.november',
    'search:range-pick.months.december'
  ],
  firstDayOfWeek: 0
};
