import { DateSchema } from '@api/types/dateSchema';
import { CountryCode } from '@components/common/country/types';
import { z } from 'zod';

export const SearchBarFormSchema = DateSchema.extend({
  city: z.string().optional(),
  country: z
    .nativeEnum(CountryCode)
    .or(z.string().transform(() => undefined))
    .optional()
});

export type SearchBarFormType = z.infer<typeof SearchBarFormSchema>;
