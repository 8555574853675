import { z } from 'zod';

export const DateSchema = z.object({
  dates: z.object({
    dateFrom: z.string(),
    dateTo: z.string()
  }),
  fromDateTime: z.string(),
  toDateTime: z.string()
});

export type DateType = z.infer<typeof DateSchema>;
