import { getCities as getCitiesApi } from '../endpoints/cities';
import { transformCities } from '../transform/cities';

export const getCities = async () => {
  try {
    return transformCities(await getCitiesApi());
  } catch (e) {
    console.log(e);
    return null;
  }
};
